import React from 'react';
import './Navbar.scss'; // Ensure to create a corresponding CSS file
import { Link } from 'react-scroll';

const Navbar = () => {
    // const [activeStates, setActiveStates] = useState([false, false, false, false, false]);

    // const handleSetActive = index => {
    //     const newActiveStates = activeStates.map((state, i) => index === i);
    //     setActiveStates(newActiveStates);
    // };

    return (
        // <div className="radio-inputs">
        //     <label className="radio">
        //         <input type="radio" name="radio" checked="" />
        //         <a href=""><span className="name">
        //             Home
        //         </span></a>
        //     </label>
        //     <label className="radio">
        //         <input type="radio" name="radio" />
        //         <span className="name">
        //             <a href="#about">About</a>
        //         </span>
        //     </label>
        //     <label className="radio">
        //         <input type="radio" name="radio" />
        //         <span className="name">
        //             <a href="#services">Service</a>
        //         </span>
        //     </label>
        //     <label className="radio">
        //         <input type="radio" name="radio" />
        //         <span className="name">
        //             <a href="#career">Career</a>
        //         </span>
        //     </label>
        //     <label className="radio">
        //         <input type="radio" name="radio" />
        //         <span className="name">
        //             <a href="#footer">Footer</a>
        //         </span>
        //     </label>
        // </div >
        <div className="Nav">
            <h2 className="home-heading">CLIPBAIT MEDIA</h2>
            <div className="footer-nav">
                <Link to="home" smooth={true} duration={500}>Home</Link>
                <Link to="about" smooth={true} duration={500}>About</Link>
                <Link to="services" smooth={true} duration={500}>Services</Link>
                <Link to="career" smooth={true} duration={500}>Careers</Link>
                <Link to="footer" smooth={true} duration={500}>Contact</Link>
            </div>
        </div>





    );
};

export default Navbar;
