import './index.scss';



import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
// import Service from './components/Services/Service';
// import Footer from './components/Footer/Footer';
import Career from './components/Career/Career';
import Footer from './components/Footer/Footer';







const App = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Services />
      <Career />
      <Footer />
    </div>
  );
};

export default App;
