import './Footer.scss';
import { Link } from 'react-scroll';

const Footer = () => {

    const logo = require('../../images/clipbait logo.png')
    const twitter = require('../../images/social/twitter.png')
    const linkedin = require('../../images/social/linkedin.png')
    const youtube = require('../../images/social/yt.png')


    return (
        <div id="footer" className="footer">

            <div className="footer1">

                <div className="footer1-1"><p>upgrade to a better future</p></div>

                <div className="footer1-2">
                    <div className="footer-inv">Coffee, questions, and a connection – let's make it happen!</div>

                    <div className="footer-buttons">
                        <button class="join-us">
                            <span class="circle" aria-hidden="true">
                                <span class="icon arrow"></span>
                            </span>
                            <span class="button-text">Join Us</span>
                        </button>

                        <button class="join-us">
                            <span class="circle" aria-hidden="true">
                                <span class="icon arrow"></span>
                            </span>
                            <span class="button-text">Contact Us</span>
                        </button>
                    </div>
                </div>

            </div>




            <div className="footer2">
                <div className="footer-nav">
                    <Link to='home' smooth={true} duration={500}>Home</Link>
                    <Link to='about' smooth={true} duration={500}>About</Link>
                    <Link to='services' smooth={true} duration={500}>Services</Link>
                    <Link to='career' smooth={true} duration={500}>Career</Link>
                </div>

                <div className="footer-social">
                    <img src={twitter} alt="twitter" />
                    <img src={linkedin} alt="linkedin" />
                    <img src={youtube} alt="youtube" />

                </div>
            </div>

        </div>



    );
}

export default Footer;




