import React from 'react';
import './Career.scss'; // Importing your CSS file
const arrow = require('../../images/arrow.png');

const Career = () => {




    return (
        <div id="career" className="Career">
            <h2>Career</h2>

            <details close>
                <summary>
                    <div className="job_name">VFX </div>
                    <div className="job_locationarrow">
                        <div className="location">Bangalore</div>
                        <img src={arrow} alt="arrow" />
                    </div>
                </summary>
                <div className="accordion-content">

                    <div className="job-cards">
                        <div class="location-box">
                            <div class="location-ball">
                                <div class="location_heading">LOCATION</div>
                                <div class="ball"></div>
                            </div>

                            <div class="job-location">Bangalore</div>

                        </div>

                        <div class="location-box">
                            <div class="location-ball">
                                <div class="location_heading">CONTACT</div>
                                <div class="ball"></div>
                            </div>

                            <div class="job-location">hello@clipbaitmedia.com</div>
                        </div>
                    </div>


                    <div class="job-roles">
                        <h2>Roles</h2>
                        <div className="roles">
                            <div class="role"><span>VFX Artist</span></div>
                            <div class="role"><span>Motion Graphics Artist</span></div>
                            <div class="role"><span>Compositing Artist</span></div>
                        </div>

                    </div>

                    <div className="responsibility">
                        <ul>
                            <li>Proficient in Adobe-Suite.</li>
                            <li>Based in Bangalore.</li>
                            <li>Exploring ideas in your head in slo-mo? </li>
                            <li>Hit us up with your showreel & resume!</li>
                        </ul>
                    </div>
                </div>
            </details>


            <details>
                <summary>
                    <div className="job_name">3D</div>
                    <div className="job_locationarrow">
                        <div className="location">Bangalore</div>
                        <img src={arrow} alt="arrow" />
                    </div>
                </summary>
                <div className="accordion-content">
                    <div className="job-cards">
                        <div class="location-box">
                            <div class="location-ball">
                                <div class="location_heading">LOCATION</div>
                                <div class="ball"></div>
                            </div>

                            <div class="job-location">Bangalore</div>

                        </div>

                        <div class="location-box">
                            <div class="location-ball">
                                <div class="location_heading">CONTACT</div>
                                <div class="ball"></div>
                            </div>

                            <div class="job-location">hello@clipbaitmedia.com</div>

                        </div>


                    </div>


                    <div class="job-roles">
                        <h2>Roles</h2>
                        <div className="roles">
                            <div class="role"><span>3D Modellers</span></div>
                            <div class="role"><span>Lighting Artist</span></div>
                            <div class="role"><span>Texture Artist</span></div>
                        </div>

                    </div>

                    <div className="responsibility">
                        <ul>
                            <li>Proficient in 3ds-Max.</li>
                            <li>Based in Bangalore.</li>
                            <li>Have an attitude to make it count? </li>
                            <li>Hit us up with your showreel & resume!</li>
                        </ul>
                    </div>
                </div>
            </details>


        </div>
    );
};

export default Career;
