import "./About.scss"
import React, { useState } from "react";
import { useInView } from 'react-intersection-observer';

const About = () => {

    const [hasAnimated, setHasAnimated] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: true });

    if (inView && !hasAnimated) {
        setHasAnimated(true);
    }
    const star = require('../../images/star.png')

    return (

        <>

            <div className="diagonal-sections">
                <div className="diagonal1"></div>
                <div className="diagonal2"></div>
            </div>
            <div className="about" id='about'>
                <div className={`about-container ${hasAnimated ? 'visible' : ''}`} ref={ref}>

                    <div className="about-left">

                        <h3 className="topic">About us</h3></div>
                    <div className="about-right">
                        <p className="aboutcontent1">Clipbait Media, a Video Production company in Bangalore, specializes in Animation Productions and Post Production services for global clients.</p>
                        <br />
                        <p className="aboutcontent2">Our team of passionate artists delivers solutions to brands, agencies, and studios. In the post-Covid era, technology evolution accelerates,shaping the filmmaking landscape. Clipbait integrates technology to create immersive experiences, offering Animation productions, Explainer videos, and more. Whether it's 3D/2D animations, CG, VFX, Motion Graphics, or Interactive videos, we provide a comprehensive solution for Marketing, promotional videos, and beyond. Explore the world of storytelling with Clipbait Media, your one-stop destination for video needs.</p>
                    </div>
                </div>

            </div>
        </>

    );
};

export default About;