import './Service.css';
import { useInView } from 'react-intersection-observer';
const Services = () => {

    const [ref, isVisible] = useInView({ threshold: 0.1 });
    const serviceMan = require('../../images/service man.png')

    const nuke = require('../../images/tools/Nuke.png')
    const threeDSMax = require('../../images/tools/3ds Max.png')
    const adobe = require('../../images/tools/Adobe.png')
    const davinciRes = require('../../images/tools/DaVinci Resolve.png')
    const maya = require('../../images/tools/maya.png')
    const cinemafourD = require('../../images/tools/4d cinema.png')

    return (
        <>        <div id="services" class="services">
            <div class="containerL">
                <h2 class="heading">Expertise</h2>
                <img
                    src={serviceMan}
                    alt="Image"
                    class="image"
                />
            </div>

            <div className={`containerR ${isVisible ? 'isvisible' : 'opacity:0.5'}`} ref={ref}>
                <div class="content">
                    <p class="content1">
                        Unleash creativity with diverse services<br />
                        Experience a new realm of storytelling excellence.
                    </p>

                    <p class="content2">
                        Clipbait Media stands as a beacon of excellence in the digital
                        realm, offering a spectrum of specialized services tailored to your
                        brand's success. From captivating Animation Productions to
                        meticulous Post Production services, our dedicated team delivers
                        innovative solutions. Dive into a world of creativity with our 3D/2D
                        animations, CGI, VFX, Motion Graphics, and Interactive videos.
                        Partner with us for comprehensive Marketing and promotional video
                        solutions, and let your brand story shine with Clipbait Media.
                    </p>
                </div>

                <table>
                    <tbody>
                        <tr>
                            <td class="service"><h3 class="ser-name">ANIMATION</h3></td>
                            <div class="ser-list">
                                <p>2D Animation</p>
                                <p>3D Animation</p>
                                <p>3D Modelling/CGI</p>
                                <p>Animatics</p>
                            </div>
                        </tr>
                        <br />
                        <tr>
                            <td class="service"><h3 class="ser-name">POST-PRODUCTION</h3></td>
                            <div class="ser-list">
                                <p>Compositing</p>
                                <p>Rotoscopy</p>
                                <p>Green Screen</p>
                                <p>Motion Graphics</p>
                                <p>Video Ediitng</p>
                                <p>Color Grading</p>
                            </div>
                        </tr>
                        <br />
                        <tr>
                            <td class="service"><h3 class="ser-name">DESIGN</h3></td>
                            <div class="ser-list">
                                <p>Concept Design</p>
                                <p>Matte painting</p>
                                <p>Look Development</p>
                            </div>
                        </tr>
                        <br />
                        <tr>
                            <td class="service"><h3 class="ser-name">SPECIALIZED</h3></td>
                            <div class="ser-list">
                                <p>360<sup>.</sup> Video</p>
                                <p>On-set SUpervision</p>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

            <div class="tools">

                <div className="tools-slide">
                    <img src={nuke} alt="nuke" />
                    <img src={maya} alt="maya" />
                    <img src={adobe} alt="adobe" />
                    <img src={threeDSMax} alt="3dsmax" />
                    <img src={davinciRes} alt="davinci" />
                    <img src={cinemafourD} alt="cinema4D" />
                </div>
                <div className="tools-slide">
                    <img src={nuke} alt="nuke" />
                    <img src={maya} alt="maya" />
                    <img src={adobe} alt="adobe" />
                    <img src={threeDSMax} alt="3dsmax" />
                    <img src={davinciRes} alt="davinci" />
                    <img src={cinemafourD} alt="cinema4D" />
                </div>
                <div className="tools-slide">
                    <img src={nuke} alt="nuke" />
                    <img src={maya} alt="maya" />
                    <img src={adobe} alt="adobe" />
                    <img src={threeDSMax} alt="3dsmax" />
                    <img src={davinciRes} alt="davinci" />
                    <img src={cinemafourD} alt="cinema4D" />
                </div>


            </div>

        </>
    );
}

export default Services;